import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component, useContext, useEffect, useState } from 'react'
import WorkInProgress from './views/workInProgress'
import superagent from 'superagent'

import { SnackbarProvider } from 'notistack';
import config from './config'
import {
  BrowserRouter,
  Routes,
  Route,
  NavLink,
  useLocation,
} from 'react-router-dom'
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history'

import './index.css';
import * as serviceWorker from './serviceWorker';

import { KLangContext, KLangProvider } from './stores/KLangProvider'
import Home from './layout/Home/Home'
import LoginPage from './views/LoginPage/LoginPage'

import 'assets/scss/material-kit-react.css?v=1.3.0'
import NotLogged from "./components/NotLogged";

const hist = createBrowserHistory()
const root = ReactDOM.createRoot(document.getElementById('root'));

const fetchIsAuthenticated = async () => {
  const oauthIsAuthenticatedUrl = config.oauth.isAuthenticated.url
  let apiResponseNetwork
  try {
    apiResponseNetwork = await superagent
      .get(oauthIsAuthenticatedUrl)
      .set('X-JWT', window.localStorage.getItem('JWT'))
      .withCredentials()

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.code !== 'ok') { return false }
    return (responseKeepapi.data.auth === true)
  } catch (error) { return false }
}
const oauth = {
  isAuthenticated: false,
  async authenticate(cb) {
    this.isAuthenticated = await fetchIsAuthenticated()
    if (this.isAuthenticated === true) {
      setTimeout(cb, 100) // fake async
    }
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}




const Login = (props) => {
  const {  ...rest } = props
  
  const {initLangStore} = useContext(KLangContext)
  let location = useLocation()
  let [langStoreReady, setLangStoreReady] = useState(false)

  let [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const openPrivateRoute = () => {
    oauth.authenticate(() => {
      setRedirectToReferrer(true)
    })
  }
  useEffect(() => { openPrivateRoute() }, [])
  initLangStore().then(data => {
    setLangStoreReady(true)
  })
  let { from } = location.state || { from: { pathname: '/' } }
  if (redirectToReferrer) return <NavLink to={from} />

  if (!!langStoreReady === false) return null

  return (

    <LoginPage openPrivateRoute={openPrivateRoute} />
  )

}


const maintenance = false
if (maintenance === false || window.localStorage.getItem('maintenance') === 'false') {
  root.render(
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <KLangProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<NotLogged />} />
            <Route path="/*" element={<Home />}>

            </Route>
          </Routes>
        </KLangProvider>
      </SnackbarProvider>
    </BrowserRouter>

    //   <BrowserRouter history={hist}>
    //     <Routes>
    //       <Route path="/login" component={Login} />
    //       <Route path="/logout" component={Logout} />

    //       <Route path="/" component={Home} />
    //     </Routes>
    //   </BrowserRouter>

    // </KLangProvider>

  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();



} else {
  root.render(
    <WorkInProgress />
  )
}
